var axios = require('axios')
axios.defaults.withCredentials = true

if (localStorage.getItem('email')) {
  $('#email')
    .val(localStorage.getItem('email'))
}
if (localStorage.getItem('password')) {
  $('#password')
    .val(localStorage.getItem('password'))
}

$('#contactBtn')
  .click(function() {
    var firstName = $('#firstName')
      .val()
    var name = $('#name')
      .val()
    var emailContact = $('#emailContact')
      .val()
    var msg = $('#msg')
      .val()
    if (!checkEmail(emailContact)) {
      dialog.alert({ message: 'Email non valide' })
      return
    }
    if (!name || !firstName || !msg) {
      dialog.alert({ message: 'Veuillez remplir tous les champs' })
    }
    $.post(
      '/classes/Email.php', {
        a: 'contact',
        firstName: firstName,
        name: name,
        emailContact: emailContact,
        msg: msg,
      },
      function(res) {
        dialog.alert({
          message: 'Message envoyé, merci',
          callback: function() {
            window.location = window.location
          },
        })
      },
    )

  })

$('#passwordLostBtn')
  .click(function(e) {
    let email = $('#lost-password')
      .val()
    if (!email) {
      return
    }
    if (!checkEmail(email)) {
      dialog.alert({ message: 'Email non valide' })
      return
    }
    axios.get('/sanctum/csrf-cookie')
      .then(() => {
        axios.post('/password/email', { email }, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
          .then(() => {
            alert('Email envoyé à ' + email)
          })
          .catch(() => $('#forgot-form-error')
            .text('Erreur: votre email n\'a pas été trouvé.'))
      })
      .catch(() => $('#forgot-form-error')
        .text('Erreur technique, veuillez contacter le support.'))
  })